const DOMAIN = process.env.REACT_APP_API_DOMAIN

const COMMON_HEADERS = {
  'x-app-version': '0.0.1',
  'x-app-platform': 'unknown',
  'x-device-id': 'web-chat-app'
}

export const getChatToken = async (account, siwe) => {
  const headers = {
    'x-wallet-address': account,
    'x-wallet-signature': siwe.signature,
    'x-siwe-message': siwe.message.replaceAll("\n", "\\n"),
    ...COMMON_HEADERS
  }
  const res = await fetch(`https://${DOMAIN}/api/v4/chat/token/`, { headers })
  if (!res.ok) throw new Error("Cannot fetch token")
  return await res.json()
}
