import React, { useEffect, useState } from "react";
import {
  Channel,
  Chat,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

import {
  ChannelInner,
  CreateChannel,
  ListUserModal,
  Loader,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton,
} from "./components";
import { GiphyContextProvider } from "./context";
import { useConnectUser, useMobileView, useTokenProvider } from "./hooks";
import { clearAccount, persistAccount, useChatStore } from "./store";
import { getChannelListOptions } from "./channelListOptions";
import { Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/joy";
import { useAccount } from "wagmi";
import { useListUser } from "./hooks/useListUser";

const apiKey = process.env.REACT_APP_STREAM_KEY;

const MainChat = ({ chatClient, userToConnect, tokenProvider }) => {
  const toggleMobile = useMobileView();

  const roleUser = chatClient.user.role;
  const channelListOptions = getChannelListOptions(userToConnect, roleUser);
  const theme = "str-chat__theme-dark";

  return (
    <Chat client={chatClient} theme={`messaging ${theme}`}>
      <div className="chat-page">
        <MessagingSidebar
          channelListOptions={channelListOptions}
          onClick={toggleMobile}
          theme={theme}
        />
        <Channel
          maxNumberOfFiles={10}
          multipleUploads={true}
          SendButton={SendButton}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={() => null}
        >
          <GiphyContextProvider>
            <ChannelInner theme={theme} toggleMobile={toggleMobile} />
          </GiphyContextProvider>
          <ListUserModal />
        </Channel>
      </div>
    </Chat>
  );
};

const MainPage = () => {
  const account = useChatStore((state) => state.account);
  const siwe = useChatStore((state) => state.siwe);
  const { tokenProvider, isLoading: isLoadingTokenProvider } = useTokenProvider(
    account,
    siwe
  );
  const { chatClient, isLoading } = useConnectUser(
    apiKey,
    account,
    tokenProvider
  );
  const { isConnected } = useAccount();

  if (!account || !isConnected) {
    clearAccount();
    return <Navigate to={"/login"} replace />;
  }

  return (
    <Loader isLoading={isLoading} centered>
      <MainChat
        chatClient={chatClient}
        userToConnect={account}
        tokenProvider={tokenProvider}
      />
    </Loader>
  );
};

export default MainPage;
