import React from "react";

type Props = {
  isEmpty: boolean;
  children: any;
};

function EmptyData({ isEmpty, children }: Props) {
  if (isEmpty) {
    return (
      <div className="mt-3 text-center" style={{ color: "#828282" }}>
        Not found
      </div>
    );
  }
  return children;
}

export default EmptyData;
