import { createContext, useContext, useState } from "react";

type ModalContextType = {
  listUserModalContext: {
    visible: boolean;
    isCreateAGroup: boolean;
    notIds: any[];
  };
  setListUserModalContext: ({
    visible,
    isCreateAGroup,
    notIds,
  }: {
    visible: boolean;
    isCreateAGroup?: boolean;
    notIds?: any[];
  }) => void;
};

const modalDefaultValue: ModalContextType = {
  listUserModalContext: {
    visible: false,
    isCreateAGroup: false,
    notIds: [],
  },
  setListUserModalContext: ({
    visible,
    isCreateAGroup,
    notIds,
  }: {
    visible: boolean;
    isCreateAGroup?: boolean;
    notIds?: any[];
  }) => {
    return {
      visible,
      isCreateAGroup,
      notIds,
    };
  },
};

const ModalContext = createContext<ModalContextType>(modalDefaultValue);

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }: any) {
  const [listUserModalContext, setListUserModalContext] = useState(
    modalDefaultValue.listUserModalContext
  );

  const value = {
    listUserModalContext: {
      visible: listUserModalContext.visible,
      isCreateAGroup: listUserModalContext.isCreateAGroup,
      notIds: listUserModalContext.notIds,
    },
    setListUserModalContext: ({
      visible,
      isCreateAGroup,
      notIds,
    }: {
      visible: boolean;
      isCreateAGroup?: boolean;
      notIds?: any[];
    }) => {
      setListUserModalContext({
        visible,
        isCreateAGroup: isCreateAGroup || false,
        notIds: notIds || [],
      });
    },
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
