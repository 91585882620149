import React from "react";
import { logChatPromiseExecution } from "stream-chat";
import {
  MessageList,
  MessageInput,
  MessageToSend,
  Window,
  useChannelActionContext,
  Thread,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

import { MessagingChannelHeader } from "../../components";
import { useGiphyContext } from "../../context";
import type { StreamChatGenerics } from "../../types";

export type ChannelInnerProps = {
  toggleMobile: () => void;
  theme: string;
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { theme, toggleMobile } = props;
  const { giphyState, setGiphyState } = useGiphyContext();
  const { channel } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();
  const { client } = useChatContext<StreamChatGenerics>();

  const overrideSubmitHandler = async (
    message: MessageToSend<StreamChatGenerics>
  ) => {
    if (client.userID) {
      const clientJoined =
        (await channel.queryMembers({ user_id: client.userID })).members
          .length > 0;

      if (!clientJoined && client.userID) {
        await channel.addMembers([client.userID]);
      }
    }

    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith("/giphy")) {
      const updatedText = message.text.replace("/giphy", "");
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }

    setGiphyState(false);
  };

  const actions = ["delete", "edit", "flag", "mute", "react", "reply"];

  return (
    <>
      <Window>
        <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} />
        <MessageList messageActions={actions} />
        <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread />
    </>
  );
};

export default ChannelInner;
