import { CircularProgress } from "@mui/joy";
import React from "react";

type Props = {
  centered?: boolean;
  isLoading: boolean;
  children?: any;
  childrenClass?: string;
  style?: any;
};

function Loader({
  centered,
  isLoading,
  children,
  childrenClass,
  style,
}: Props) {
  if (isLoading) {
    return (
      <div
        className={`${
          centered ? "position-absolute" : "position-relative text-center"
        } ${childrenClass}`}
        style={{
          ...(centered
            ? { top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
            : {}),
          ...style,
        }}
      >
        <CircularProgress variant="solid" />
      </div>
    );
  }
  return children;
}

export default Loader;
