import React from "react";
import ReactDOM from "react-dom/client";

import "@fontsource/public-sans"; // default font used by Joy UI
import App from "./App";
import "./index.css";

const apiKey = process.env.REACT_APP_STREAM_KEY;

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);
root.render(
  <React.StrictMode>
    <App apiKey={apiKey!} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
