import type { ChannelFilters, ChannelOptions, ChannelSort } from "stream-chat";

/**
 * Exports few channel list configuration options. See the docs for more information:
 * - https://getstream.io/chat/docs/sdk/react/core-components/channel_list/
 *
 * @param user the user id.
 */
export const getChannelListOptions = (
  user: string | undefined,
  role: string | undefined
) => {
  const filters: ChannelFilters = {
    ...(role === "user"
      ? {
          members: { $in: [user!] },
          last_message_at: {
            $gt: "1970-01-01T00:00:00Z",
          },
        }
      : {
          $or: [
            {
              members: { $in: [user!] },
              last_message_at: {
                $gt: "1970-01-01T00:00:00Z",
              },
            },
            {
              $and: [
                {
                  type: { $eq: "support" },
                },
                {
                  request_to_support: { $eq: true },
                },
              ],
            },
          ],
        }),
  };

  const options: ChannelOptions = {
    state: true,
    watch: true,
    presence: true,
    limit: 8,
  };

  const sort: ChannelSort = {
    last_message_at: -1,
    updated_at: -1,
  };

  return {
    filters,
    options,
    sort,
  };
};
