import { useWeb3Modal, Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import React, { useEffect } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useCheckResponsive } from "../hooks/useCheckResponsive";

function Header() {
  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const screen = useCheckResponsive();
  useAccount({
    onDisconnect() {
      setTimeout(() => {
        open();
      }, 1000);
    },
  });

  return (
    <header
      className="mx-3 mx-md-4  fade-in"
      style={{ paddingTop: 30, paddingBottom: 30 }}
    >
      <div className="container-header me-2">
        <h2 className="mb-0" style={{ cursor: "pointer" }}>
          TIMELESS WALLET.
        </h2>
      </div>
      {screen !== "mobile" ? (
        <div className="container-web3-button">
          <div className="me-2">{isConnected && <Web3NetworkSwitch />}</div>
          <div>
            <Web3Button icon="hide" balance="show" />
          </div>
        </div>
      ) : (
        <div className="container-web3-button">
          <div className="me-2">{isConnected && <Web3NetworkSwitch />}</div>
          {isConnected && (
            <div>
              <Web3Button icon="hide" />
            </div>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
