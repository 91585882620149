import { useEffect, useState } from "react";
import {
  DefaultGenerics,
  ExtendableGenerics,
  StreamChat,
  TokenOrProvider,
} from "stream-chat";

/**
 * A hook which handles the process of connecting/disconnecting a user
 * to the Stream Chat backend.
 *
 * @param apiKey the Stream app API key to use.
 * @param userToConnect the user information.
 * @param userTokenOrProvider the user's token.
 */
export const useConnectUser = (apiKey, userToConnect, userTokenOrProvider) => {
  const [chatClient, setChatClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (!userToConnect || !userTokenOrProvider) {
      setIsLoading(false);
      return;
    }
    const client = new StreamChat(apiKey, {
      enableInsights: true,
      enableWSFallback: true,
    });

    // Under some circumstances, a "connectUser" operation might be interrupted
    // (fast user switching, react strict-mode in dev). With this flag, we control
    // whether a "disconnectUser" operation has been requested before we
    // provide a new StreamChat instance to the consumers of this hook.
    let didUserConnectInterrupt = false;
    client
      .connectUser({ id: userToConnect }, userTokenOrProvider)
      .catch((e) => {
        console.error(`Failed to connect user`, e);
      })
      .then(() => {
        if (!didUserConnectInterrupt) {
          setChatClient(client);
          setIsLoading(false);
        }
      });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient((client) => {
        if (client) {
          client.disconnectUser().catch((e) => {
            console.error(`Failed to disconnect user`, e);
          });
        }
        return null;
      });
      setIsLoading(false);
    };
  }, [apiKey, userTokenOrProvider, userToConnect]);

  return { chatClient, isLoading };
};
