import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ChannelSaveIcon, XButtonBackground } from "../../assets";
import "./ChannelInforModal.css";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import type { StreamChatGenerics } from "../../types";
import EmptyData from "../EmptyData/EmptyData";
import User from "../User/User";
import { AiFillEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { useModalContext } from "../../context/modal";
import { sortListMember, sortListUser } from "../../utils";
import AvatarGroup from "../AvatarGroup/AvatarGroup";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

function ChannelInforModal({ isOpen, setIsOpen }: Props) {
  const { client } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const channelData = channel.state._channel.data as {
    created_by: { id: string };
    name: string;
  };

  const { listUserModalContext, setListUserModalContext } = useModalContext();
  //   console.log("channelData", channelData);
  const ownerChannel = channelData?.created_by?.id;
  const isOwnerChannel = ownerChannel === client.userID;
  const members = Object.values(channel.state.members || {});
  const [isEditing, setIsEditing] = useState(false);
  const [channelNameInput, setChannelNameInput] = useState(
    channelData.name || ""
  );

  useEffect(() => {
    return () => {
      setIsEditing(false);
      setChannelNameInput(channelData.name);
    };
  }, [isOpen, channelData]);

  async function handleUpdateChannelName() {
    if (channelNameInput !== channelData.name) {
      await channel.update(
        { name: channelNameInput },
        { text: `Channel name changed to ${channelNameInput}` }
      );
    }
    setIsEditing(false);
  }

  async function handleRemoveUser(user: any) {
    if (!user) return;
    await channel.removeMembers([user.id]);
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      destroyOnClose
      centered
      closeIcon={<XButtonBackground />}
      maskStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      }}
      wrapClassName="channel-infor-modal"
      onCancel={() => setIsOpen(false)}
    >
      <div>
        <div className="">
          <div className="mb-4">
            <AvatarGroup members={members} size={120} />
            <div className="mt-3">
              {!isEditing ? (
                <div className="text-center" style={{ fontSize: 16 }}>
                  <div className="channel-name">
                    {channelData.name}{" "}
                    <span
                      className="pointer"
                      onClick={() => setIsEditing(true)}
                    >
                      <AiFillEdit />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="position-relative container-edit-input-channel">
                  <input
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdateChannelName();
                      }
                    }}
                    value={channelNameInput}
                    className="edit-input-channel-name"
                    placeholder="Type a new name for the chat"
                    onChange={(e) => setChannelNameInput(e.target.value)}
                  />
                  <div
                    onClick={handleUpdateChannelName}
                    style={{ marginTop: 4, marginRight: 8 }}
                    className="position-absolute top-0 end-0 pointer"
                  >
                    <ChannelSaveIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="mb-2 d-flex justify-content-between align-items-end">
              <div>
                <div style={{ fontSize: 24, fontFamily: "Montserrat-Medium" }}>
                  Members
                </div>
                <div style={{ fontSize: 12, color: "#acacac" }}>
                  {members.length} members
                </div>
              </div>
              <div
                className="pointer d-flex align-items-center"
                onClick={() => {
                  setListUserModalContext({
                    visible: true,
                    notIds: members
                      .map((member) => member.user_id)
                      .filter((memberId) => memberId !== client.userID),
                  });
                }}
              >
                <div className="me-1">
                  <AiOutlinePlusCircle size={22} />
                </div>
                <div>Invite members</div>
              </div>
            </div>
            <EmptyData isEmpty={members.length <= 0}>
              <div className="list-members">
                {sortListMember(members).map((member) => {
                  return (
                    <div key={member.user_id}>
                      <User
                        user={member.user}
                        isShowOwner={ownerChannel === member.user_id}
                        onClickRemove={handleRemoveUser}
                        isOwnerChannel={isOwnerChannel}
                      />
                    </div>
                  );
                })}
              </div>
            </EmptyData>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChannelInforModal;
