import type { MouseEventHandler } from "react";
import { ChannelList, ChannelListProps } from "stream-chat-react";
import { MessagingChannelListHeader, MessagingChannelPreview } from "../index";

type MessagingSidebarProps = {
  channelListOptions: {
    filters: ChannelListProps["filters"];
    sort: ChannelListProps["sort"];
    options: ChannelListProps["options"];
  };
  onClick: MouseEventHandler;
  theme: string;
};

const MessagingSidebar = ({
  channelListOptions,
  onClick,
  theme,
}: MessagingSidebarProps) => {
  return (
    <div
      className={`str-chat messaging__sidebar ${theme}`}
      id="mobile-channel-list"
    >
      <MessagingChannelListHeader />
      <div>
        <ChannelList
          {...channelListOptions}
          Preview={(props) => (
            <MessagingChannelPreview
              {...props}
              onClick={(e) => {
                onClick(e);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MessagingSidebar;
