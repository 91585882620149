import { useCallback, useState } from "react";

import { useChatStore, persistToken } from "../store";
import { getChatToken } from "../api";
import { isTokenValid } from "../utils";

export const useTokenProvider = (account, siwe) => {
  const token = useChatStore((state) => state.token);
  const [isLoading, setIsLoading] = useState(true);

  const tokenProvider = useCallback(async () => {
    setIsLoading(true);
    const token = await getChatToken(account, siwe);
    persistToken(token);
    setIsLoading(false);
    return token.access_token;
  }, [account, siwe]);

  if (!account || !siwe) return { tokenProvider: null, isLoading: false };
  if (isTokenValid(token)) {
    return { tokenProvider: token.access_token, isLoading: false };
  }
  return { tokenProvider, isLoading };
};
