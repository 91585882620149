import React, { useEffect, useState } from "react";
import { Button, Typography, Alert, CircularProgress } from "@mui/joy";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import {
  clearAccount,
  persistAccount,
  persistSiwe,
  useChatStore,
} from "./store";
import { createSiweMessage } from "./utils";
import ReactPlayer from "react-player";
import { useWeb3Modal, Web3Button } from "@web3modal/react";
import MainLayout from "./layout/MainLayout";
import { Navigate, redirect } from "react-router-dom";
import { useTokenProvider } from "./hooks";
import { useCheckResponsive } from "./hooks/useCheckResponsive";

function LoginPageNewVersion() {
  const { address, isConnected, isReconnecting, isDisconnected } = useAccount();
  const siwe = useChatStore((state) => state.siwe);
  const { isOpen } = useWeb3Modal();
  const [message, setMessage] = useState("");
  const {
    data,
    isError,
    isLoading: isSigningMessage,
    isSuccess,
    signMessage,
  } = useSignMessage({
    message: message,
  });

  const account = useChatStore((state) => state.account);

  const { tokenProvider } = useTokenProvider(account, siwe);
  const screen = useCheckResponsive();

  useEffect(() => {
    if (isConnected && !isReconnecting && !siwe) {
      persistAccount(address);
      setMessage(
        createSiweMessage(
          address,
          "Timeless-Chat wants to sign you in using your address"
        )
      );
    }
  }, [address, isConnected, isReconnecting, siwe]);

  useEffect(() => {
    if (isSuccess) {
      persistSiwe({
        message,
        signature: data,
      });
    }
  }, [isSuccess, message, data]);

  useEffect(() => {
    if (message) {
      signMessage();
    }
  }, [message]);

  if (tokenProvider && isConnected) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <div className="login-page container">
      <div
        className="d-flex flex-column align-items-center position-relative"
        style={{ paddingBottom: 70 }}
      >
        <div
          className={`container-video ${
            isOpen && screen !== "mobile" ? "fade-out" : "fade-in"
          }`}
          style={{ height: "calc(var(--app-height) - 80px)" }}
        >
          <ReactPlayer
            url={require("./assets/mp4/CULTURE-AND-TECH-TW-480.mp4")}
            muted
            loop
            playsinline
            playing
            width={"100%"}
            height={"100%"}
          />
        </div>
        <div
          className="text-container text-center position-absolute"
          style={{ bottom: 0 }}
        >
          <h3 style={{ fontFamily: "Montserrat-Medium" }}>
            Timeless Chat Login
          </h3>
          <p style={{ fontFamily: "Montserrat-Medium", opacity: 0.8 }}>
            Frens don’t let frens use ugly wallets.
          </p>
          <div className="mt-4">
            {isConnected && !isError ? (
              <div className="text-center" style={{ color: "#fff" }}>
                <CircularProgress variant="solid" />
                <p>Signing you in...</p>
              </div>
            ) : (
              <Web3Button icon="hide" />
            )}
            {isError && (
              <Alert
                sx={{ alignItems: "flex-start" }}
                endDecorator={
                  <Button
                    onClick={() => signMessage()}
                    variant="solid"
                    sx={{ mr: 1 }}
                  >
                    Retry
                  </Button>
                }
                variant="soft"
                color="danger"
              >
                <div>
                  <Typography fontWeight="lg" mt={0.25}>
                    Error
                  </Typography>
                  <Typography fontSize="sm" sx={{ opacity: 0.8 }}>
                    Cannot get message signature
                  </Typography>
                </div>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPageNewVersion;
