import React from "react";
import type { ChannelMemberResponse } from "stream-chat";
import { Avatar } from "stream-chat-react";
import { getCleanImage } from "../../assets";
import "./AvatarGroup.css";
import StreamLogo from "../../assets/stream.png";

export const AvatarGroup = ({
  members,
  size = 40,
}: {
  members: ChannelMemberResponse[];
  size?: number;
}) => {
  let content = <Avatar image={StreamLogo} shape="square" size={size} />;

  if (members.length === 1) {
    content = <Avatar image={getCleanImage(members[0])} size={size} />;
  }

  if (members.length === 2) {
    content = (
      <>
        <div>
          <Avatar
            image={getCleanImage(members[0])}
            shape="square"
            size={size}
          />
        </div>
        <div>
          <Avatar
            image={getCleanImage(members[1])}
            shape="square"
            size={size}
          />
        </div>
      </>
    );
  }

  if (members.length === 3) {
    content = (
      <>
        <div>
          <Avatar
            image={getCleanImage(members[0])}
            shape="square"
            size={size}
          />
        </div>
        <div>
          <Avatar
            image={getCleanImage(members[1])}
            shape="square"
            size={size / 2}
          />
          <Avatar
            image={getCleanImage(members[2])}
            shape="square"
            size={size / 2}
          />
        </div>
      </>
    );
  }

  if (members.length >= 4) {
    content = (
      <>
        <div>
          <Avatar
            image={getCleanImage(members[members.length - 4])}
            shape="square"
            size={size / 2}
          />
          <Avatar
            image={getCleanImage(members[members.length - 3])}
            shape="square"
            size={size / 2}
          />
        </div>
        <div>
          <Avatar
            image={getCleanImage(members[members.length - 2])}
            shape="square"
            size={size / 2}
          />
          <Avatar
            image={getCleanImage(members[members.length - 1])}
            shape="square"
            size={size / 2}
          />
        </div>
      </>
    );
  }

  // fallback for channels with no avatars (single-user channels)
  return (
    <div
      className="avatar-group"
      style={{ height: size, minWidth: size, maxWidth: size }}
    >
      {content}
    </div>
  );
};

export default AvatarGroup;
