import moment from "moment";
import React, { useState } from "react";
import { AiOutlineCrown } from "react-icons/ai";
import "./User.css";

type Props = {
  user: any;
  isShowOwner?: boolean;
  onClickRemove?: (user: any) => void;
  isOwnerChannel?: boolean;
};

function User({ user, isShowOwner, onClickRemove, isOwnerChannel }: Props) {
  return (
    <div className="d-flex mb-1 p-2">
      <div className="me-3">
        <img
          src={user.image}
          alt={user.name}
          width={48}
          height={48}
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div
        className="position-relative d-flex justify-content-between"
        style={{ flex: 1 }}
      >
        <div>
          <div className="mb-1" style={{ fontSize: 16, fontWeight: 600 }}>
            {user.name} {isShowOwner && <AiOutlineCrown size={18} />}
          </div>
          <div
            className={`${
              user.online ? "user-container_online" : "user-container_offline"
            }`}
            style={{ fontSize: 12 }}
          >
            {user.online
              ? "online"
              : `Last seen: ${moment(user.last_active).fromNow()}`}
          </div>
        </div>
        {onClickRemove && !isShowOwner && isOwnerChannel && (
          <div>
            <button
              className="btn-remove-user"
              onClick={() => onClickRemove(user)}
            >
              Remove
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default User;
