import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useChatStore = create(
  persist(
    (set) => ({
      account: null,
      siwe: null,
      token: null,
    }),
    {
      name: "chat-storage", // name of the item in the storage (must be unique)
    }
  )
);

export const persistAccount = (account) => useChatStore.setState({ account });
export const clearAccount = () => {
  useChatStore.setState({ account: null, siwe: null, token: null });
};
export const persistSiwe = (siwe) => {
  useChatStore.setState({ siwe });
};

export const persistToken = (token) => useChatStore.setState({ token });
