import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useModalContext } from "../../context/modal";
import { useListUser } from "../../hooks/useListUser";
import { useConnectUser, useTokenProvider } from "../../hooks";
import { useChatStore } from "../../store";
import Loader from "../Loader/Loader";
import moment from "moment";
import "./ListUserModal.css";
import {
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import type { StreamChatGenerics } from "../../types";
import { XButtonBackground } from "../../assets";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyData from "../EmptyData/EmptyData";
import User from "../User/User";
import LoadingHorizontal from "../Loader/LoadingHorizontal";
import { sortListUser } from "../../utils";

function ListUserModal() {
  const { listUserModalContext, setListUserModalContext } = useModalContext();
  const account = useChatStore((state) => state.account);
  const siwe = useChatStore((state) => state.siwe);
  const { tokenProvider } = useTokenProvider(account, siwe);
  const {
    listUser,
    isLoading,
    searchEmpty,
    searching,
    onLoadmore,
    onSearchUser,
  }: {
    listUser: any[];
    searching: boolean;
    isLoading: boolean;
    searchEmpty: boolean;
    onLoadmore: () => void;
    onSearchUser: (value: string) => void;
  } = useListUser(listUserModalContext.visible, listUserModalContext.notIds);
  const [listMemberSelected, setListMemberSelected] = useState<any>([]);
  const [groupNameInput, setGroupNameInput] = useState("");
  const modeInviteUser = listUserModalContext.notIds.length > 0;
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext();

  useEffect(() => {
    if (!listUserModalContext.visible) {
      setListMemberSelected([]);
    }
  }, [listUserModalContext]);

  function handleSelectMember(user: any) {
    let tempArray = [...listMemberSelected];
    const findIdx = tempArray.findIndex((member) => member.id === user.id);
    if (findIdx !== -1) {
      tempArray.splice(findIdx, 1);
    } else {
      tempArray.push(user);
    }
    setListMemberSelected(tempArray);
  }

  async function handleCreateGroupChat({ listMember }: { listMember: any[] }) {
    const selectedUsersIds = listMember.map((u) => u.id);
    if (!selectedUsersIds.length || !client.userID) return;
    const conversation = client.channel("messaging", uuidv4(), {
      name: groupNameInput || `${client.user?.name}'s group`,
      members: [...selectedUsersIds, client.userID],
    });

    await conversation.watch();
    setActiveChannel?.(conversation);
  }

  async function handleCreateDirectChat(user: any) {
    if (!user || !client.userID) return;

    const conversation = client.channel("messaging", {
      members: [user.id, client.userID],
    });

    await conversation.watch();
    setActiveChannel?.(conversation);
    setListUserModalContext({ visible: false });
  }

  async function handleInviteMember({ listMember }: { listMember: any[] }) {
    const selectedUsersIds = listMember.map((u) => u.id);
    if (!selectedUsersIds.length) return;
    await channel.addMembers(selectedUsersIds);

    setListUserModalContext({ visible: false, notIds: [] });
  }

  return (
    <div>
      <Modal
        open={listUserModalContext.visible}
        centered
        destroyOnClose
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
        closeIcon={<XButtonBackground />}
        wrapClassName="list-user-modal"
        onOk={() => {
          if (listUserModalContext.isCreateAGroup) {
            handleCreateGroupChat({ listMember: listMemberSelected });
          }
          setListUserModalContext({ visible: false });
        }}
        onCancel={() => setListUserModalContext({ visible: false })}
        {...(!listUserModalContext.isCreateAGroup && { footer: null })}
      >
        <div>
          {listUserModalContext.isCreateAGroup && (
            <div className="mb-3" style={{ fontSize: 18 }}>
              <div>Create a group</div>
              <input
                className="input-group-name w-100"
                type="text"
                value={groupNameInput}
                onChange={(e) => setGroupNameInput(e.target.value)}
                placeholder="Enter group name"
              />
            </div>
          )}
          <div className="" style={{ fontSize: 18 }}>
            Sorted by last seen time
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <input
              className="input-group-name w-100 me-3"
              type="text"
              // value={searchNameInput}
              onChange={(e) => {
                // setSearchNameInput(e.target.value);
                onSearchUser(e.target.value);
              }}
              placeholder="Search user"
            />
            {modeInviteUser && listMemberSelected.length > 0 && (
              <div
                onClick={() =>
                  handleInviteMember({ listMember: listMemberSelected })
                }
                className="pointer"
                style={{ color: "#3898FF" }}
              >
                Done
              </div>
            )}
          </div>
          <Loader
            isLoading={isLoading}
            childrenClass="mt-4 mb-2 pe-2"
            style={{ minHeight: 283 }}
          >
            <EmptyData isEmpty={searchEmpty}>
              <div id="list-user" className="list-user mt-4 mb-2 pe-2">
                <InfiniteScroll
                  next={() => {
                    onLoadmore();
                  }}
                  hasMore={true}
                  loader={<Loader isLoading={true} />}
                  dataLength={listUser.length}
                  scrollableTarget="list-user"
                >
                  {listUser.length > 0 &&
                    sortListUser(listUser).map((user) => {
                      return (
                        <div
                          key={user.id}
                          className={`user-container ${
                            listMemberSelected.find(
                              (member: any) => member.id === user.id
                            )
                              ? "user-container_selected"
                              : ""
                          }`}
                          onClick={() => {
                            if (
                              listUserModalContext.isCreateAGroup ||
                              modeInviteUser
                            ) {
                              handleSelectMember(user);
                            } else {
                              handleCreateDirectChat(user);
                            }
                          }}
                        >
                          <User user={user} />
                        </div>
                      );
                    })}
                </InfiniteScroll>
              </div>
            </EmptyData>
          </Loader>
        </div>
      </Modal>
    </div>
  );
}

export default ListUserModal;
