import React, { useEffect, useRef, useState } from "react";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import "./MessagingChannelHeader.css";

import { TypingIndicator } from "../TypingIndicator/TypingIndicator";
import { ChannelInfoIcon, ChannelSaveIcon, HamburgerIcon } from "../../assets";
import { AvatarGroup, ChannelInforModal } from "../";

import type { StreamChatGenerics } from "../../types";
import { Dropdown, MenuProps } from "antd";
import { IoVolumeMute } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";

type Props = {
  theme: string;
  toggleMobile: () => void;
};

const MessagingChannelHeader = (props: Props) => {
  const { theme, toggleMobile } = props;
  const { client } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext();
  const [isOpenChannelInforModal, setIsOpenChannelInforModal] = useState(false);

  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id
  );

  const channelData = channel.state._channel.data as {
    created_by: { id: string };
    name: string;
  };
  const [channelName, setChannelName] = useState(channelData.name || "Unnamed");
  const [channelIsMuted, setChannelIsMuted] = useState(
    channel.muteStatus().muted
  );

  useEffect(() => {
    if (!channelData.name) {
      setChannelName(
        members
          .map(
            (member) => member.user?.name || member.user?.id || "Unnamed User"
          )
          .join(", ")
      );
    } else {
      setChannelName(channelData.name);
    }
  }, [channelData, members]);

  async function handleDeleteConversation({ channel }: { channel: any }) {
    try {
      const isOwnChannel = channelData.created_by.id === client.userID;
      if (isOwnChannel) {
        const destroy = await client.deleteChannels([channel.cid]);
      } else {
        await channel.removeMembers([client.userID]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div onClick={() => handleDeleteConversation({ channel: channel })}>
          {members.length > 1 ? "Delete and leave" : "Delete"}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => setIsOpenChannelInforModal(true)}>Channel Info</div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={async () => {
            if (channel.muteStatus().muted) {
              await channel.unmute();
              setChannelIsMuted(false);
            } else {
              await channel.mute();
              setChannelIsMuted(true);
            }
          }}
        >
          {channelIsMuted ? "Unmute" : "Mute"}
        </div>
      ),
    },
  ];

  return (
    <div className="messaging__channel-header">
      <div
        id="mobile-nav-icon"
        className={`${theme}`}
        onClick={() => {
          toggleMobile();
        }}
      >
        <HamburgerIcon />
      </div>
      <AvatarGroup members={members} />
      <div className="channel-header__name">
        {channelName} {channelIsMuted && <IoVolumeMute color="#828282" />}
      </div>
      <TypingIndicator />
      <Dropdown
        menu={{
          items:
            members.length > 1
              ? items
              : items.filter((menu) => menu?.key !== "2"),
          style: { marginTop: 8 },
        }}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div className="messaging__channel-header__right pointer">
          {/* <ChannelInfoIcon /> */}
          <AiOutlineInfoCircle size={24} />
        </div>
      </Dropdown>
      <ChannelInforModal
        isOpen={isOpenChannelInforModal}
        setIsOpen={setIsOpenChannelInforModal}
      />
    </div>
  );
};

export default MessagingChannelHeader;
