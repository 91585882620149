import moment from "moment";
import { useEffect, useState } from "react";
import {
  DefaultGenerics,
  ExtendableGenerics,
  StreamChat,
  TokenOrProvider,
} from "stream-chat";
import { useChatContext } from "stream-chat-react";
import _debounce from "lodash.debounce";

const limit = 50;
const limitSearch = 20;
export const useListUser = (fetching, notIds = []) => {
  const [listUser, setListUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { client } = useChatContext();
  const [searching, setSearching] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [inputSearch, setInputSearch] = useState(false);

  useEffect(() => {
    if (fetching) {
      fetchListUser();
      return () => {
        setListUser([]);
        setIsLoading(false);
        setIsLoading(false);
        setSearching(false);
      };
    }
  }, [client, fetching]);

  async function fetchListUser() {
    setIsLoading(true);
    client
      .queryUsers(
        {
          $and: [
            { id: { $exists: true } },
            { last_active: { $exists: true } },
            {
              id: {
                $nin: [client.userID, ...notIds],
              },
            },
            { last_active: { $lte: moment().format() } },
          ],
        },
        {
          last_active: -1,
          id: -1,
        },
        { presence: true, limit: limit, offset: 0 }
      )
      .then((result) => {
        setListUser(result.users);
        setIsLoading(false);
        setSearchEmpty(false);
      })
      .catch((error) => console.log(error));
  }

  function onLoadmore() {
    client
      .queryUsers(
        {
          $and: [
            { id: { $exists: true } },
            { last_active: { $exists: true } },
            { id: { $ne: client.userID } },
            { last_active: { $lte: moment().format() } },
          ],
        },
        {
          last_active: -1,
          id: -1,
        },
        { presence: true, limit: limit, offset: listUser.length }
      )
      .then((result) => {
        setListUser((prev) => [...prev, ...result.users]);
      })
      .catch((error) => console.log(error));
  }

  const findUsersDebounce = _debounce(
    (value) => {
      if (value.length > 0) {
        findUsers(value);
      } else {
        fetchListUser();
      }
    },
    1000,
    {
      trailing: true,
    }
  );

  const findUsers = async (inputText) => {
    if (searching) return;
    setSearching(true);

    try {
      const response = await client.queryUsers(
        {
          id: { $ne: client.userID },
          $and: [{ name: { $autocomplete: inputText } }],
        },
        { id: 1 },
        { limit: limitSearch }
      );
      if (!response.users.length) {
        setSearchEmpty(true);
      } else {
        setSearchEmpty(false);
        setListUser(response.users);
      }
    } catch (error) {
      console.log({ error });
    }

    setSearching(false);
  };

  function onSearchUser(value) {
    setInputSearch(value);
    findUsersDebounce(value);
  }

  return {
    listUser,
    isLoading,
    onLoadmore,
    onSearchUser,
    searchEmpty,
    searching,
  };
};
