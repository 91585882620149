import React from "react";
import { Avatar, useChatContext } from "stream-chat-react";

import streamLogo from "../../assets/stream.png";
import type { StreamChatGenerics } from "../../types";
import { Dropdown, MenuProps } from "antd";
import { useModalContext } from "../../context/modal";
import { HiEllipsisHorizontal } from "react-icons/hi2";

type Props = {};

const MessagingChannelListHeader = React.memo(() => {
  const { client } = useChatContext<StreamChatGenerics>();
  const { setListUserModalContext } = useModalContext();

  const {
    id,
    image = streamLogo as string,
    name = "Example User",
  } = client.user || {};

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            setListUserModalContext({
              visible: true,
              isCreateAGroup: true,
              notIds: [],
            })
          }
        >
          Create a group
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => setListUserModalContext({ visible: true })}>
          Send a direct message
        </div>
      ),
    },
  ];

  return (
    <div className="messaging__channel-list__header">
      <Avatar image={image} name={name} size={40} />
      <div className={`messaging__channel-list__header__name`}>
        {name || id}
      </div>

      <Dropdown
        menu={{ items, style: { marginTop: 8 } }}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <button className={`messaging__channel-list__header__button`}>
          <HiEllipsisHorizontal />
        </button>
      </Dropdown>
    </div>
  );
});

export default React.memo(MessagingChannelListHeader);
