import { useEffect, useLayoutEffect, useState } from "react";

export const useCheckResponsive = () => {
  const [screen, setScreen] = useState(checkScreen(window.innerWidth));
  useEffect(() => {
    window.addEventListener("resize", () => {
      const result = checkScreen(window.innerWidth);
      setScreen(result);
    });
    return () => {
      window.removeEventListener("resize", () =>
        checkScreen(window.innerWidth)
      );
    };
  }, []);

  function checkScreen(width: number) {
    // const width = window.innerWidth;
    if (width >= 1920) {
      return "desktop";
    } else if (width < 1920 && width >= 1024) {
      return "small-desktop";
    } else if (width < 1024 && width > 768) {
      return "tablet";
    } else {
      return "mobile";
    }
  }

  return screen;
};
