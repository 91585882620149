import { extendTheme } from "@mui/joy/styles";
import { SiweMessage } from "siwe";
import dayjs from "dayjs";
import { createBreakpoints } from "@mui/system";
import _ from "lodash";

const domain = window.location.host;
const origin = window.location.origin;

export function createSiweMessage(address, statement) {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: "1",
  });
  return message.prepareMessage();
}

export function isTokenValid(token) {
  return (
    !!token &&
    !!token.expired_at &&
    dayjs(token.expired_at) > dayjs().add(30, "minute")
  );
}

export function sortListMember(arr) {
  const tempArray = _.orderBy(
    [...arr],
    ["role", "user.online", "user.last_active", "user.name"],
    ["desc", "desc", "desc", "asc"]
  );
  return tempArray;
}

export function sortListUser(arr) {
  const tempArray = _.orderBy(
    [...arr],
    ["online", "last_active", "name"],
    ["desc", "desc", "asc"]
  );
  return tempArray;
}

const breakpoints = createBreakpoints({});
export const muiTheme = extendTheme({
  fontFamily: {
    display: "'Inter', var(--joy-fontFamily-fallback)",
    body: "'Inter', var(--joy-fontFamily-fallback)",
  },
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: "#C0CCD9",
          100: "#A5B8CF",
          200: "#6A96CA",
          300: "#4886D0",
          400: "#2178DD",
          500: "#096BDE",
          600: "#3898FF",
          700: "#265995",
          800: "#2F4968",
          900: "#2F3C4C",
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 600,
      [breakpoints.down("md")]: {
        fontSize: 20,
      },
      [breakpoints.up("md")]: {
        fontSize: 24,
      },
      [breakpoints.up("lg")]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      [breakpoints.down("md")]: {
        fontSize: 18,
      },
      [breakpoints.up("md")]: {
        fontSize: 20,
      },
      [breakpoints.up("lg")]: {
        fontSize: 24,
      },
    },
    body1: {
      fontSize: 18,
      [breakpoints.down("md")]: {
        fontSize: 14,
      },
      [breakpoints.up("md")]: {
        fontSize: 16,
      },
      [breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
  },
});
