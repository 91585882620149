import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { useWeb3ModalTheme, Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, polygon } from "wagmi/chains";
import "bootstrap/dist/css/bootstrap.min.css";
import "stream-chat-react/dist/css/v2/index.css";
import "./styles/index.css";

import MainPage from "./MainPage";
import LoginPage from "./LoginPage";
import LoginPageNewVersion from "./LoginPageNewVersion";
import {
  useConnectUser,
  useUpdateAppHeightOnResize,
  useTokenProvider,
} from "./hooks";
import { useChatStore } from "./store";
import MainLayout from "./layout/MainLayout";
import { useEffect } from "react";
import {
  Await,
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { CircularProgress } from "@mui/joy";
import { ListUserModal, Loader } from "./components";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { ModalProvider } from "./context/modal";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnItb6IBAZAP0IkSt94IhE_Rg9CD8wfbc",
  authDomain: "staging-chatstream-zien.firebaseapp.com",
  projectId: "staging-chatstream-zien",
  storageBucket: "staging-chatstream-zien.appspot.com",
  messagingSenderId: "534608107886",
  appId: "1:534608107886:web:871384bfbd7d08b0eb897f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const chains = [mainnet, polygon];
const projectId = process.env.REACT_APP_WC_PROJECT_ID;

// Visit https://explorer.walletconnect.com/?search=timeless to fetch data
const timelessWalletId =
  "9751385960bca290c13b443155288f892f62ee920337eda8c5a8874135daaea8";
const timelessWallet = {
  id: timelessWalletId,
  name: "Timeless Wallet",
  links: {
    native: "timeless-wallet://",
  },
};

const walletImages = {
  [timelessWalletId]: `https://explorer-api.walletconnect.com/v3/logo/lg/32e89601-0490-42fc-0cc4-8627d62a2000?projectId=${projectId}`,
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  { path: "/login", element: <LoginPageNewVersion /> },
]);

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const App = (props) => {
  const { setTheme } = useWeb3ModalTheme();
  useUpdateAppHeightOnResize();
  setTheme({
    themeMode: "dark",
    themeVariables: {
      "--w3m-font-family": "Montserrat-Regular",
      "--w3m-accent-color": "#3898FF",
      "--w3m-button-border-radius": "32px",
    },
  });

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <ModalProvider>
          <MainLayout>
            <RouterProvider router={router} />
          </MainLayout>
        </ModalProvider>
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        mobileWallets={[timelessWallet]}
        walletImages={walletImages}
      />
    </>
  );
};

export default App;
